import React from 'react';
import styled from 'styled-components';
import errorDisc from '../assets/svg/disc/error-disc.svg';

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  padding: 20px;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #afb6b9;
`;

const StyledIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 20px;

  @media (min-width: 576px) {
    width: 60px;
    height: 60px;
  }
`;

const Message = styled.p`
  font-size: 14px;
  color: #0c1f28;
  margin-bottom: 8px;
  font-weight: bold;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const SubMessage = styled.p`
  font-size: 14px;
  color: #0c1f28;
  margin-bottom: 0;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const StyledLink = styled.a`
  margin-top: 8px;
  font-size: 14px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const StyledButton = styled.button`
  margin-top: 8px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  text-decoration: underline !important;
  color: #ff0085;
  display: inline-block;
  font-size: 14px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const WarningPanel = ({
  title = '',
  body = '',
  linkText = '',
  linkUrl = null,
  linkAction = null,
}) => {
  return (
    <EmptyStateContainer>
      <StyledIcon src={errorDisc} alt="Error disc" />
      <Message>{title}</Message>
      <SubMessage>{body}</SubMessage>
      {linkText && linkUrl && (
        <StyledLink href={linkUrl}>{linkText}</StyledLink>
      )}
      {linkAction && linkText && !linkUrl && (
        <StyledButton onClick={linkAction}>{linkText}</StyledButton>
      )}
    </EmptyStateContainer>
  );
};

export default WarningPanel;
