import styled from 'styled-components';
import { StyledH3 } from '../../Styled';
import ImportExportChart from './ImportExportChart';
import formatDate from '../../../utils/formatDate';
import { secondary } from '../../../theme/colors';

const Container = styled.div`
  margin-top: 50px;

  @media (min-width: 576px) {
    margin-top: 80px;
  }
`;

const H3 = styled(StyledH3)`
  margin-bottom: 5px;

  span.type {
    color: ${secondary};
  }

  span.cost {
    font-weight: normal;
  }
`;

const GridExportsGraph = ({ savingsData, boundaryData, hasPeak, date }) => {
  const exportEarnings =
    savingsData?.reduce((acc, row) => acc + row.earnings, 0) / 100;

  const haveEarningsForTheDay = savingsData?.some(row => row.earnings > 0);

  return haveEarningsForTheDay ? (
    <Container>
      <H3>
        Grid <span className="type">export</span> earnings on {formatDate(date)}
        : <span className="cost">£{exportEarnings.toFixed(2)}</span>
      </H3>
      <ImportExportChart
        data={savingsData}
        boundaryData={boundaryData}
        hasPeak={hasPeak}
        barColor={secondary}
        yAxisLabel="Export earnings (p)"
        xAxisLabel="Time of day"
      />
    </Container>
  ) : null;
};

export default GridExportsGraph;
