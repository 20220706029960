const awsmobile = {
  aws_project_region: 'eu-west-1',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_AqXJoNpjB',
  aws_user_pools_web_client_id: '4kg5kjkbqof7pv7dfgkratarcl',
  location_endpoint_auth:
    'https://api-preview.location-service.preview.trustpower.io/v1/geo-location',
  optomize_endpoint_auth:
    'https://api-preview.optimise-domain.preview.trustpower.io/v1',
  data_bucket:
    'https://loop-optimise-instructions-preview.s3.eu-west-1.amazonaws.com',
  dashboard_url: 'https://webapp.loop-optimise.preview.trustpower.io',
  signup_simulation_mode: true,
};
export default awsmobile;
