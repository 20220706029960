import moment from 'moment';
import styled from 'styled-components';
import { StyledH3 } from '../../Styled';
import ImportExportChart from './ImportExportChart';
import formatDate from '../../../utils/formatDate';
import { cheap, day, peak, primary } from '../../../theme/colors';
import getHasPeak from '../../../utils/getHasPeak';

const Container = styled.div`
  margin-top: 50px;

  @media (min-width: 576px) {
    margin-top: 80px;
  }
`;

const H3 = styled(StyledH3)`
  margin-bottom: 5px;

  span.type {
    color: ${primary};
  }

  span.cost {
    font-weight: normal;
  }
`;

const colorMap = {
  'Cheap rate': cheap,
  'Day rate': day,
  'Peak rate': peak,
};

const GridImportsGraph = ({ dailyAnalyticsData, date }) => {
  const data = dailyAnalyticsData?.map(row => ({
    data: row,
    earnings: parseFloat(row.actual_import_cost) * -1,
    time: row.time,
  }));

  const highestDayEarnings = data?.reduce(
    (acc, row) => (row.earnings > acc ? row.earnings : acc),
    0,
  );

  const boundaryData = data?.map(row => ({
    time: row.time,
    boundary: row?.data?.tariff_rate,
    color: colorMap[row?.data?.tariff_rate],
    value:
      row?.data?.tariff_rate == 'Cheap rate' ||
      row?.data?.tariff_rate == 'Peak rate'
        ? highestDayEarnings
        : 0,
  }));

  const hasPeak = getHasPeak(boundaryData);

  const importCosts = data?.reduce((acc, row) => acc + row.earnings, 0) / 100;

  const haveCostsForTheDay = data?.some(row => row.earnings > 0);

  return haveCostsForTheDay ? (
    <Container>
      <H3>
        Grid <span className="type">import</span> costs on {formatDate(date)}:{' '}
        <span className="cost">£{importCosts.toFixed(2)}</span>
      </H3>
      <ImportExportChart
        data={data}
        boundaryData={boundaryData}
        hasPeak={hasPeak}
        barColor={primary}
        yAxisLabel="Import costs (p)"
        xAxisLabel="Time of day"
      />
    </Container>
  ) : null;
};

export default GridImportsGraph;
