import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import styled from 'styled-components';
import Accordion from '../../Accordion';
import StyledTitle from '../../Styled/StyledTitle';
import { black, grey, light, secondary, white } from '../../../theme/colors';

const Container = styled.div`
  margin-top: 24px;
`;

const StyledHighlightInfo = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: rgba(43, 70, 177, 0.1);
  padding: 14px 22px;
  border-radius: 8px;
  font-style: italic;

  p:last-child {
    margin-bottom: 0;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  text-align: left;

  @media (min-width: 576px) {
    font-size: 18px;
  }

  th,
  td {
    padding: 12px;
  }

  th {
    font-weight: bold;
    vertical-align: top;
  }

  tr:nth-child(even) {
    background-color: #eff2f6;
  }
`;

const FurtherInfo = ({
  intersectionX,
  intersectionY,
  historicChartData,
  behaviorChartData,
}) => {
  const accordionData = [
    {
      title: 'Show list of changes to the battery so far today',
      content: (
        <div>
          <StyledTable>
            <thead>
              <tr>
                <th>Time</th>
                <th className="text-right">Target charge</th>
              </tr>
            </thead>
            <tbody>
              {historicChartData?.map(row => (
                <tr key={`row-${row.x}`}>
                  <td>{row.x}</td>
                  <td className="text-right">{Math.round(row.y)}%</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <StyledTitle>Your personalised Optimisation plan:</StyledTitle>
      {historicChartData && behaviorChartData && (
        <VictoryChart
          containerComponent={
            <VictoryVoronoiContainer
              voronoiBlacklist={['historicChart', 'nowDot', 'nowLine']}
              labels={({ datum }) =>
                `Time: ${datum.x}\nState of charge: ${Math.round(datum.y)}%`
              }
              labelComponent={
                <VictoryTooltip
                  cornerRadius={2}
                  pointerLength={4}
                  style={{ fontSize: 12 }}
                  flyoutStyle={{
                    fill: white,
                    stroke: 'lightgrey',
                  }}
                />
              }
            />
          }>
          <VictoryAxis
            dependentAxis
            tickValues={[0, 25, 50, 75, 100]}
            label="Battery state of charge (%)"
            style={{
              axis: { stroke: 'transparent' },
              ticks: { stroke: 'transparent' },
              tickLabels: {
                fill: black,
                fontSize: 10,
                padding: 5,
                fontWeight: 'normal',
              },
              axisLabel: {
                fontSize: 12,
                padding: 35,
                angle: -90,
                textAnchor: 'middle',
                fill: black,
                fontWeight: 'normal',
              },
              grid: {
                stroke: grey,
              },
            }}
          />
          <VictoryArea
            data={behaviorChartData}
            style={{
              data: {
                fill: 'none',
                stroke: secondary,
                strokeDasharray: '5.5,3',
                strokeWidth: 1.6,
              },
            }}
          />
          <VictoryArea
            name="historicChart"
            data={historicChartData}
            style={{
              data: {
                fill: secondary,
                stroke: secondary,
                strokeWidth: 2,
                fillOpacity: 0.1,
              },
            }}
          />
          <VictoryAxis
            tickValues={behaviorChartData.map(d => d.x)}
            label="Half-hourly time period"
            tickFormat={(t, index, ticks) => {
              if (
                index === 0 ||
                index === ticks.length - 1 ||
                t === intersectionX
              ) {
                return t;
              }
              return '';
            }}
            style={{
              ticks: { stroke: light, size: 5 },
              tickLabels: {
                fill: black,
                fontSize: 10,
                padding: 5,
              },
              axis: { stroke: light, strokeWidth: 2 },
              axisLabel: {
                fontSize: 12,
                padding: 30,
                textAnchor: 'middle',
                fill: black,
                fontWeight: 'normal',
              },
            }}
          />
          <VictoryLine
            name="nowLine"
            data={[
              { x: intersectionX, y: -5 },
              { x: intersectionX, y: 120 },
            ]}
            style={{
              data: {
                stroke: black,
                strokeWidth: 2,
                fillOpacity: 0.5,
              },
            }}
            labels={() => 'Now'}
            labelComponent={
              <VictoryLabel
                dy={7}
                backgroundPadding={{ top: 8, bottom: 6, left: 14, right: 14 }}
                backgroundStyle={{ fill: black }}
                style={{ fill: white, fontSize: 10, fontWeight: 'bold' }}
                backgroundComponent={<rect rx="2" ry="2" />}
              />
            }
          />
          <VictoryScatter
            name="nowDot"
            data={[
              {
                x: intersectionX,
                y: intersectionY,
              },
            ]}
            size={5}
            style={{
              data: { fill: secondary, stroke: white, strokeWidth: 2 },
            }}
          />
        </VictoryChart>
      )}
      <p>
        Loop optimises your electricity bill by charging your battery when
        electricity is cheap, and using this energy when electricity is
        expensive.
      </p>

      <StyledHighlightInfo>
        <p>
          Loop’s optimisation runs every half hour and reacts to changes in your
          energy demand or generation.
        </p>
      </StyledHighlightInfo>

      <Accordion data={accordionData} />
    </Container>
  );
};

export default FurtherInfo;
