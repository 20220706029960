import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import LoadingCard from '../../LoadingCard';
import StyledTitle from '../../Styled/StyledTitle';
import InfoModal from '../../Modal/InfoModal';
import { useState } from 'react';
import { StyledAnchor } from '../../Styled';
import { black, grey, light, secondary, white } from '../../../theme/colors';

const BatteryStateOfCharge = ({ data, fetchingAnalyticsData }) => {
  const intersectionX = data && data[data?.length - 1]?.x;
  const [showInfo, setShowInfo] = useState(false);

  return (
    <>
      <InfoModal
        show={showInfo}
        onClose={() => setShowInfo(false)}
        infoContent={
          <div className="pb-3">
            The chart shows the state of charge of the battery for the date
            selected.
          </div>
        }
      />
      <div
        className="d-flex justify-content-between"
        style={{ marginTop: 60, marginBottom: 0 }}>
        <StyledTitle>Battery state of charge</StyledTitle>
        <StyledAnchor onClick={() => setShowInfo(true)}>?</StyledAnchor>
      </div>
      {fetchingAnalyticsData && (
        <div className="mt-4">
          <LoadingCard />
        </div>
      )}
      {!fetchingAnalyticsData && data?.length > 0 && (
        <div>
          <VictoryChart
            width={648}
            height={350}
            containerComponent={
              <VictoryVoronoiContainer
                voronoiDimension="x"
                labels={({ datum }) =>
                  `Time: ${datum?.x}\nState of charge: ${datum.y}%`
                }
                labelComponent={
                  <VictoryTooltip
                    cornerRadius={2}
                    pointerLength={4}
                    style={{ fontSize: 16 }}
                    flyoutPadding={{ top: 8, bottom: 8, left: 8, right: 8 }}
                    flyoutStyle={{
                      fill: white,
                      stroke: grey,
                    }}
                  />
                }
              />
            }>
            <VictoryAxis
              dependentAxis
              tickValues={[0, 25, 50, 75, 100]}
              label="Battery state of charge (%)"
              style={{
                ticks: { stroke: 'transparent' },
                tickLabels: {
                  fill: black,
                  fontSize: 14,
                  padding: 5,
                },
                axis: { stroke: 'transparent' },
                axisLabel: {
                  fontSize: 17,
                  padding: 35,
                  textAnchor: 'middle',
                  fill: black,
                  fontWeight: 'normal',
                },
                grid: {
                  stroke: grey,
                  strokeWidth: 0.7,
                  strokeDasharray: '0',
                },
              }}
            />
            <VictoryAxis
              tickValues={data.map(d => d?.x)}
              label="Half-hourly time period"
              tickFormat={(t, index, ticks) => {
                if (
                  index === 0 ||
                  index === ticks.length - 1 ||
                  ['06:00', '12:00', '18:00'].includes(t) ||
                  t === intersectionX
                ) {
                  return t;
                }
                return '';
              }}
              style={{
                ticks: { stroke: light, size: 5 },
                axis: { stroke: light, strokeWidth: 2 },
                tickLabels: {
                  fill: black,
                  fontSize: 14,
                  padding: 5,
                },
                axisLabel: {
                  fontSize: 17,
                  padding: 30,
                  textAnchor: 'middle',
                  fill: black,
                  fontWeight: 'normal',
                },
              }}
            />
            <VictoryArea
              data={data}
              style={{
                data: {
                  fill: secondary,
                  fillOpacity: 0.1,
                  stroke: secondary,
                  strokeWidth: 3,
                },
              }}
            />
          </VictoryChart>
        </div>
      )}
    </>
  );
};

export default BatteryStateOfCharge;
