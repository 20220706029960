import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLegend,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import { cheap, peak, white } from '../../../theme/colors';

const ImportExportChart = ({
  data,
  boundaryData,
  hasPeak,
  barColor,
  yAxisLabel,
  xAxisLabel,
}) => {
  const legendData = [
    { name: 'Cheap rate', symbol: { fill: cheap } },
    { name: 'Day rate', symbol: { fill: 'transparent', stroke: 'black' } },
  ];

  if (hasPeak) {
    legendData.push({ name: 'Peak rate', symbol: { fill: peak } });
  }

  return (
    <div style={{ position: 'relative' }}>
      <VictoryChart
        domainPadding={10}
        width={648}
        height={420}
        padding={{ top: 30, right: 50, bottom: 71, left: 60 }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => {
              if (datum.earnings) {
                return `£${datum.earnings.toFixed(2)}`;
              } else if (datum.boundary) {
                return datum.boundary;
              }
            }}
            labelComponent={
              <VictoryTooltip
                cornerRadius={4}
                pointerLength={10}
                style={{ fontSize: 16 }}
                flyoutStyle={{
                  fill: white,
                  stroke: 'lightgrey',
                }}
                flyoutPadding={{ top: 4, bottom: 5, left: 8, right: 8 }}
              />
            }
          />
        }>
        <VictoryLegend
          x={50}
          y={0}
          orientation="horizontal"
          gutter={20}
          style={{ title: { fontSize: 14 } }}
          data={legendData}
        />
        <VictoryAxis
          label={xAxisLabel}
          tickFormat={(t, index) => {
            if (
              [
                '03:00',
                '06:00',
                '09:00',
                '12:00',
                '15:00',
                '18:00',
                '21:00',
                '00:00',
              ].includes(t)
            ) {
              return t;
            }
            return '';
          }}
          style={{
            axis: { stroke: '#afb6b9' },
            axisLabel: {
              fontSize: 16,
              padding: 50,
            },
            ticks: { stroke: 'transparent', size: 5 },
            tickLabels: {
              fontSize: 14,
              padding: 5,
              angle: -90,
              textAnchor: 'end',
            },
            grid: { stroke: 'transparent' },
          }}
          offsetY={70}
        />
        <VictoryAxis
          dependentAxis
          label={yAxisLabel}
          style={{
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
            tickLabels: {
              fill: 'black',
              fontSize: 14,
              padding: 5,
            },
            grid: {
              stroke: '#afb6b9',
              opacity: 0.3,
              strokeWidth: 0.5,
              strokeDasharray: '0',
            },
            axisLabel: {
              fontSize: 16,
              padding: 40,
              textAnchor: 'middle',
              fontWeight: 'bold',
              fill: barColor,
            },
          }}
        />
        {/* Boundary Data Bar */}
        <VictoryBar
          data={boundaryData}
          x="time"
          y="value"
          barWidth={12}
          style={{ data: { fill: ({ datum }) => datum.color } }}
        />

        {/* Earnings Data Bar */}
        <VictoryBar
          data={data}
          x="time"
          y="earnings"
          barWidth={10}
          style={{ data: { fill: barColor } }}
        />
      </VictoryChart>
    </div>
  );
};

export default ImportExportChart;
