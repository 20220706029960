import styled from 'styled-components';
import batteryIcon from '../../../assets/svg/today/battery.svg';
import moment from 'moment';
import StyledTitle from '../../Styled/StyledTitle';

const Container = styled.div`
  margin-top: 24px;
`;

const Timestamp = styled.p`
  font-size: 14px;
  margin-bottom: 12px;
  text-align: center;

  @media (min-width: 576px) {
    text-align: left;
    font-size: 16px;
  }
`;

const BatteryInfo = styled.div`
  border: 1px solid #afb6b9;
  border-radius: 8px;
  padding: 10px 16px;
  margin-bottom: 10px;

  @media (min-width: 576px) {
    display: flex;
    align-items: center;
  }
`;

const BatteryIcon = styled.div`
  width: 42px;
  height: 42px;
  background: linear-gradient(225deg, #6433a5, #2747b1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    width: 60px;
    height: 60px;
    margin-bottom: 0;
    margin-right: 16px;
  }
`;

const BatteryIconImg = styled.img`
  width: 12px;

  @media (min-width: 576px) {
    width: 18px;
  }
`;

const BatteryDetails = styled.div`
  flex: 1;
`;

const StatusText = styled.p`
  margin: 2px 0;
  font-size: 14px;
  text-align: center;

  @media (min-width: 576px) {
    text-align: left;
    font-size: 16px;
  }
`;

const RecentBatteryStatus = ({
  latestRecord,
  lastInstruction,
  nextInstruction,
}) => {
  const lastRecordMoment = moment(latestRecord?.end_date * 1000);
  return (
    <Container>
      <StyledTitle>Most recent home battery status: </StyledTitle>
      <Timestamp>
        {!!latestRecord?.end_date &&
          `${lastRecordMoment.format('DD/MM/YY [at] HH:mm')} (${lastRecordMoment.fromNow()})`}
      </Timestamp>
      <BatteryInfo>
        <BatteryIcon>
          <BatteryIconImg src={batteryIcon} alt="Battery" />
        </BatteryIcon>
        <BatteryDetails>
          <StatusText>
            Battery status:{' '}
            <strong>
              {lastInstruction?.battery_update_command === 'discharge'
                ? 'Discharging'
                : 'Charging'}
            </strong>
          </StatusText>
          <StatusText>
            Battery state of charge:{' '}
            <strong>{Math.floor(latestRecord?.battery_soc)}%</strong>
          </StatusText>
          <StatusText>
            Next battery action:{' '}
            <strong>
              {nextInstruction?.battery_update_command === 'discharge'
                ? 'Discharge'
                : 'Charge'}{' '}
              at {nextInstruction?.fullTimeVector.substring(11, 16)}
            </strong>
          </StatusText>
        </BatteryDetails>
      </BatteryInfo>
    </Container>
  );
};

export default RecentBatteryStatus;
